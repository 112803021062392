import logo from './logo.svg';
import React, { useContext } from 'react';
import './App.css';
import Canvas from './components/Canvas';
import {Fileupload } from './components/fileupload';
import { Imagelist} from './components/imagelist';
import { useState } from 'react';
import DataContext from './store/data-context';

function App() {
  const [canvasRef, setcanvasRef] = useState();
  const [refresh, setrefresh] = useState(false);
  const [arrImglist, setarrImagelist] = useState([]);
  const [renderedImaglist , setrenderedImagelist] = useState([]);
  const renderedImaglistRef = React.useRef(renderedImaglist);
  const [onmovex, setonmovex] = useState(4);
  const [onmovey, setonmovey] = useState(4);
  const [activeItemindex, setactiveItemindex] = useState(0);
  const [removeItemindex, setremoveItemindex] = useState(-1);

  const ctx = useContext(DataContext);
  const getContext = (canvasref)=>{
    setcanvasRef(canvasref);
    
  }

  const getimagefiles = (arrImagelist)=>{
    setarrImagelist(arrImagelist);
  }

  const getrenderedImglist=(rimgRef)=>{
    if(renderedImaglistRef.current !== undefined){
      renderedImaglistRef.current.splice(0, renderedImaglistRef.current.length, ...rimgRef.current);
      //setrenderedImagelist([]);
      setrenderedImagelist(rimgRef.current);
    }
    
  }

 const onUpdateX = (index, value)=>{
    setonmovex(value);
    setactiveItemindex(index);
 }

 const onUpdateY = (index, value)=>{
    setonmovey(value);
    setactiveItemindex(index);
 }

 const onNotifyDelete = (index) =>{
  console.log('notify',renderedImaglistRef.current);
  setremoveItemindex(index);
  setactiveItemindex(0);
 }

  return (
    <DataContext.Provider
      value={{
        arrImagelist: arrImglist,
        renderedImaglist: renderedImaglist
      }}
    >
    <div >
      <div className='parent-container'>
        <section className='leftbar'>
          <Fileupload getimagefiles={getimagefiles}/>
          <Imagelist canvasRef={canvasRef} 
          getrenderedImglist={getrenderedImglist} 
          onupdatex={onmovex} onupdatey={onmovey} activeItemindex={activeItemindex}
          onremoveindex={removeItemindex}/>
          
        </section>
        <section className='canvas-container'>
          {/* <Canvas  
          getcontext={getContext} onChangeX={onUpdateX} onChangeY={onUpdateY}
          onRemoveItem={onNotifyDelete}/> */}
        </section>
      </div>
    </div>
    </DataContext.Provider>
  );
}

export default App;
