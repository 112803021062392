import React, { useContext, useEffect, useState, useRef } from "react";
import  ImageItem  from "../imageItem";
import ImageData from "../imageData";
import Placeholder from "../imageItem/placholder";
import DataContext from "../../store/data-context";
import { FloatButton } from 'antd';
import { Button, Drawer, InputNumber, Space } from 'antd';
import { PlusOutlined, DeleteOutlined,MenuOutlined,ExportOutlined,UploadOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { toCanvas } from 'html-to-image';

export const Imagelist = ({arrImglist, canvasRef, 
  getrenderedImglist, onupdatex,onupdatey,
  activeItemindex,onremoveindex}, props) =>{

    const [activeIndex, setactiveIndex] = useState(0);
    const [context, setcontext] = useState();
    const contextRef = React.useRef(context);
    const [boundings, setboundings] = useState({});
    const boundingsRef = React.useRef(boundings);
    const [mouseX, setmouseX] = useState(0);
    const mouseXRef = React.useRef(mouseX);
    const [mouseY, setmouseY] = useState(0);
    const mouseYRef = React.useRef(mouseY);
    const [isMoving, setisMoving] = useState(false);
    const isMovingRef = React.useRef(isMoving);
    const [isResize, setisResize] = useState(false);
    const [renderedImaglist, setrenderedImagelist] = useState([]);
    const renderedImaglistRef = React.useRef(renderedImaglist);
    const [activeImageItem, setactiveImageItem] = useState();
    const activeImageItemRef = React.useRef(activeImageItem);
    const [open, setOpen] = useState(false);
    
    const ctx = useContext(DataContext);
    const elementRef = useRef(null);
    let Apiurl = process.env.REACT_APP_APIURL;
    useEffect(()=>{
      if(renderedImaglistRef.current.length > 0){
        let newarr = renderedImaglist.filter((item,index)=>{
          if(index !== onremoveindex) return item;
        });
        renderedImaglist.splice(0, renderedImaglist.length, ...newarr);
        renderedImaglistRef.current = renderedImaglist;
        setrenderedImagelist(renderedImaglist);
        getrenderedImglist(renderedImaglistRef);
        drawAllShapes();
        console.log('imglist:',renderedImaglistRef.current);
      
      }
    },[onremoveindex]);

    useEffect(()=>{
      setMouseXCoordinate(onupdatex);
      //setMouseYCoordinate(onupdatey);
      
      if(renderedImaglistRef.current.length > 0){
        let imgitem = renderedImaglistRef.current[activeItemindex];
        imgitem.dx = onupdatex ;
        //imgitem.dy = onupdatey;
        console.log(onupdatex);
        drawAllShapes();
      }
      
    },[onupdatex,activeItemindex]);

    useEffect(()=>{
     // setMouseXCoordinate(onupdatex);
      setMouseYCoordinate(onupdatey);
      
      if(renderedImaglistRef.current.length > 0){
        let imgitem = renderedImaglistRef.current[activeItemindex];
        //imgitem.dx = onupdatex ;
        imgitem.dy = onupdatey;
        console.log(onupdatey);
        drawAllShapes();
      }
      
    },[onupdatey,activeItemindex])

    useEffect(()=>{
        if(canvasRef !== undefined){
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            //ctx.scale(0.25,0.25);
            contextRef.current = ctx;
            setcontext(ctx);
            let bounds = canvas.getBoundingClientRect();
            boundingsRef.current = bounds;
            setboundings(bounds);
            canvas.addEventListener('mousedown', handleMousedown);
            canvas.addEventListener('mousemove', handleMousemove);
            canvas.addEventListener('mouseup', handleMouseup);
            
            console.log('canvas useeffect');
          }
    },[canvasRef])

    const handleClick = (index) => {
        // now update activeIndex
        setactiveIndex(index);

      }

      // Handle Mouse Coordinates
  const setMouseCoordinates = (event)=> {
    
    let bounds = boundingsRef.current;
    let x = event.clientX - bounds.left;
    let y = event.clientY - bounds.top;
    mouseXRef.current = x;
    setmouseX(x);
    mouseYRef.current = y;
    setmouseY(y);
  }

  const setMouseXCoordinate = (x) =>{
    
    let bounds = boundingsRef.current;
    let newx = x - bounds.left; 
    mouseXRef.current = newx;
    setmouseX(newx);
  }

  const setMouseYCoordinate = (y) =>{
    
    let bounds = boundingsRef.current;
    let newy = y - bounds.top; 
    mouseYRef.current = newy;
    setmouseY(newy);
  }

  function drawRectanglePlaceholder(imgitem){
    let placeholderPadding = 4;
    fillBoundingBox(imgitem,placeholderPadding);
    drawActivePlaceHolder(imgitem.dx  - placeholderPadding,
        imgitem.dy - placeholderPadding,
      (imgitem.dWidth ) + (placeholderPadding * 2),
      (imgitem.dHeight) + (placeholderPadding * 2));
  }

  const fillBoundingBox = (imgitem)=>{
    imgitem.boundingRect = [
        [imgitem.dx - 4, imgitem.dy - 4],
    [imgitem.dx + (imgitem.dWidth),imgitem.dy  - 4],
    [imgitem.dx + (imgitem.dWidth ),imgitem.dy + (imgitem.dHeight)],
    [imgitem.dx - 4, imgitem.dy + (imgitem.dHeight)]
      ];
}

  function drawActivePlaceHolder(x,y,width,height){
    let placeholder = new Placeholder(0,0);
    placeholder.context = contextRef.current;
    placeholder.x = x;
    placeholder.y = y;
    placeholder.width = width;
    placeholder.height = height;
    placeholder.draw();
    // let temparr = [...renderedImaglist,placeholder];
    //     renderedImaglistRef.current = temparr;
    //     setrenderedImagelist(temparr);
  }

  function removePlaceHolder(){
   let temparr = renderedImaglist.filter((item)=>{
      if(item.type !== 'placeholder'){
        return item;
      }
    });
    renderedImaglistRef.current = temparr;
        setrenderedImagelist(temparr);
  }

  function drawAllShapes(){
    // // Clear canvas
    // contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    // let rimagelist = renderedImaglistRef.current;
    // // Draws Balls
    // for(var i = 0; i < rimagelist.length; i++){
    //     rimagelist[i].draw();
    // }
    
  }

  const isHitOnImage = (mx,my) =>{
    drawAllShapes();
    let rimaglist = renderedImaglistRef.current;
    console.log(rimaglist);
    for(var i = rimaglist.length - 1; i >= 0; i--){
        if(pointInPolygon([mx,my], rimaglist[i].boundingRect)){
            activeImageItemRef.current = rimaglist[i];
            setactiveImageItem(rimaglist[i]);
            //activeShape = shapesArr[i];
            drawRectanglePlaceholder(rimaglist[i]);
            console.log(rimaglist[i]);
            isMovingRef.current = true;
              setisMoving(true);
              return;
          }
          
    }
  }

      const handleMousedown = (event)=>{
        event.preventDefault();
        
        setMouseCoordinates(event);
        //removePlaceHolder();
        isHitOnImage(mouseXRef.current,mouseYRef.current);
    }


      const handleMousemove = (event)=>{
        if(isMovingRef.current){
            let placeholderPadding = 4;
            setMouseCoordinates(event);
            let imgitem = activeImageItemRef.current;
            imgitem.dx = mouseXRef.current - (imgitem.dWidth/2);
            imgitem.dy = mouseYRef.current - (imgitem.dHeight/2);
                //placeholder.x = imgitem.x  - placeholderPadding;
                //placeholder.y = imgitem.y - placeholderPadding;
                fillBoundingBox(imgitem,placeholderPadding);
                //drawAllShapes();
        }
        
      }
      const handleMouseup = (event)=>{
        console.log('mouseup');
        
        setMouseCoordinates(event);
        if(isMovingRef.current){
          isMovingRef.current = false;
          setisMoving(false);
          drawAllShapes();
        }
        
    }

   

    function pointInPolygon(point, vs) {
        if(vs === null || undefined) return;
        const x = point[0], y = point[1];
        let wn = 0;
      
        for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
          let xi = vs[i][0], yi = vs[i][1];
          let xj = vs[j][0], yj = vs[j][1];
      
          if (yj <= y) {
            if (yi > y) {
              if (isLeft([xj, yj], [xi, yi], [x,y]) > 0) {
                wn++;
              }
            }
          } else {
            if (yi <= y) {
              if (isLeft([xj, yj], [xi, yi], [x, y]) < 0) {
                wn--;
              }
            }
          }
        }
        return wn != 0;
      };

      function isLeft(P0, P1, P2) {
        let res = ( (P1[0] - P0[0]) * (P2[1] - P0[1])
                  - (P2[0] -  P0[0]) * (P1[1] - P0[1]) );
        return res;
      }

      // const addImagetoCanvas = (index)=>{
      //   setactiveIndex(index);
      //   let event = {
      //     clientX: renderedImaglist.length * 150,
      //     clientY: 4
      //   }
      //   setMouseCoordinates(event);
      //   let imgitem = new ImageItem();
      //   imgitem.context = context;
      //   imgitem.src = process.env.REACT_APP_IMAGEURL + ctx.arrImagelist[index].sImageURL;
      //   imgitem.dx = renderedImaglist.length * 150;
      //   imgitem.dy = 4;
        
      //   imgitem.draw();
      //   fillBoundingBox(imgitem);
      //   let temparr = [...renderedImaglist,imgitem];
      //   renderedImaglistRef.current = temparr;
      //   setrenderedImagelist(temparr);
      //   getrenderedImglist(renderedImaglistRef);
        
      // }

      const addImagetoCanvas = (index)=>{
        setactiveIndex(index);
        
        let imgitem = new ImageData();
        imgitem.src = process.env.REACT_APP_IMAGEURL + ctx.arrImagelist[index].sImageURL;
        imgitem.mystyle.left = (renderedImaglist.length * 150);
        imgitem.mystyle.top = 4 ;
        imgitem.mystyle.zIndex = renderedImaglist.length;
        
        
        let temparr = [...renderedImaglist,imgitem];
        renderedImaglistRef.current = temparr;
        setrenderedImagelist(temparr);
        getrenderedImglist(renderedImaglistRef);
      }

      const deleteImagefromlist = ()=>{

      }

      const htmlToImageConvert = () => {
        renderedImaglistRef.current.sort((a, b) => a.mystyle.zIndex - b.mystyle.zIndex);
        let data = [...renderedImaglistRef.current];
        console.log("fetch",data);
        fetch(
          `${Apiurl}nodejs/fileupload/export_image`,
          {
            method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                      },
                      body:JSON.stringify({data:data})
          }
        )
          .then((response) => response.json())
          .then((result) => {
                    alert("Done");
          })
          .catch((error) => {
            console.error('Error:', error);
          });

        // toCanvas(elementRef.current, { cacheBust: false })
        //   .then((canvas) => {
        //     // const link = document.createElement("a");
        //     // link.download = "my-image-name.png";
        //     // link.href = dataUrl;
        //     // link.click();
        //     var imageName = prompt('Please enter image name');
        // var canvasDataURL = canvas.toDataURL();
        // var a = document.createElement('a');
        // a.href = canvasDataURL;
        // a.download = imageName || 'drawing';
        // a.click();
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      };

      const filename = (name)=>{
        if(name !== undefined){
          let arr = name.split('/');
          return arr[arr.length-1];
        }
        
    }

    const onMouseXChange = (index,value)=>{
        let temparr = renderedImaglistRef.current.map((item,idx) => idx === index ? {...item, mystyle:{...item.mystyle, left:value }} : item);
        renderedImaglistRef.current = temparr;
        setrenderedImagelist(temparr);
    }

    const onMouseYChange = (index,value)=>{
      let temparr = renderedImaglistRef.current.map((item,idx) => idx === index ? {...item, mystyle:{...item.mystyle, top:value }} : item);
      renderedImaglistRef.current = temparr;
      setrenderedImagelist(temparr);
    }

    const onZindexChange = (index,value)=>{
      let temparr = renderedImaglistRef.current.map((item,idx) => idx === index ? {...item, mystyle:{...item.mystyle, zIndex:value }} : item);
      renderedImaglistRef.current = temparr;
      setrenderedImagelist(temparr);
    }

    const onWidthChange = (index,value)=>{
      let temparr = renderedImaglistRef.current.map((item,idx) => idx === index ? {...item, mystyle:{...item.mystyle, width:value }} : item);
      renderedImaglistRef.current = temparr;
      setrenderedImagelist(temparr);
    }

    const onHeightChange = (index,value)=>{
      let temparr = renderedImaglistRef.current.map((item,idx) => idx === index ? {...item, mystyle:{...item.mystyle, height:value }} : item);
      renderedImaglistRef.current = temparr;
      setrenderedImagelist(temparr);
    }

    

    const onDeleteClick = (idx) =>{
      //onRemoveItem(index);
      if(renderedImaglistRef.current.length > 0){
        let newarr = renderedImaglist.filter((item,index)=>{
          if(index !== idx) return item;
        });
        //renderedImaglist.splice(0, renderedImaglist.length, ...newarr);
        renderedImaglistRef.current = newarr;
        setrenderedImagelist(newarr);
      }
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

return(
  <div>
    {/* images on the top of the list  */}
    
    <div className="img-container">
      {
        ctx.arrImagelist &&
        ctx.arrImagelist.map((img, index) =>
          <div className={activeIndex === index ? 'img-item active' : 'img-item'} key={index} onClick={handleClick.bind(this, index)}>
            <div className="img-actions">
              <PlusOutlined key="Add Image to canvas" onClick={addImagetoCanvas.bind(this, index)} />
              <DeleteOutlined key="Remove from the image list" onClick={deleteImagefromlist} />
            </div>
            <img src={process.env.REACT_APP_IMAGEURL + img.sImageURL} />
          </div>
        )
      }
      
      <FloatButton icon={<ExportOutlined /> } type="primary" onClick={htmlToImageConvert}
        style={{ right: 64 }} />
    </div>
      {/* tree list items */}
    <div className="canvas-workarea">
        <FloatButton icon={<MenuOutlined /> } type="primary" onClick={showDrawer}
        style={{ left: 64, top:248 }} />
    <Drawer
        title="List of images added"
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        key="left"
      >
        <ul>
        {

          renderedImaglist && renderedImaglist.map((img, index) =>

            <li className='tree-listitem' key={index}>

              <div className='tree-listitem-meta'>
                <img src={img.src} />
                <p>{filename(img.src)}</p>
              </div>
              <div className='listitem-oper'>
                <div className='listitem-location'>
                  <div>
                    <label>X:</label>
                    <InputNumber min={4} defaultValue={img.mystyle.left} onChange={onMouseXChange.bind(this, index)} />
                  </div>
                  <div>
                    <label>Y:</label>
                    <InputNumber min={4} defaultValue={img.mystyle.top} onChange={onMouseYChange.bind(this, index)} />
                  </div>
                  <div>
                    <label>Z-index:</label>
                    <InputNumber min={0} defaultValue={img.mystyle.zIndex} onChange={onZindexChange.bind(this, index)} />
                  </div>
                  <div>
                    <label>Width:</label>
                    <InputNumber min={50} defaultValue={img.mystyle.width} onChange={onWidthChange.bind(this, index)} />
                  </div>
                  <div>
                    <label>Height:</label>
                    <InputNumber min={50} defaultValue={img.mystyle.height} onChange={onHeightChange.bind(this, index)} />
                  </div>
                </div>

                <Button type="primary" onClick={onDeleteClick.bind(this, index)}
                  icon={<DeleteOutlined />}>Remove from canvas</Button>

              </div>


            </li>
          )
        }
      </ul>
      </Drawer>
      
      {/* html container, canvas is not used. */}
      <div className="canvas-container" ref={elementRef}>
        {
          renderedImaglistRef.current &&
          renderedImaglistRef.current.map((img, index) =>

            <img src={img.src} style={img.mystyle} />


          )
        }
      </div>
    </div>
  </div>
)
}