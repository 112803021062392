import React, { useState, useEffect } from 'react';

const DataContext = React.createContext({
//   isLoggedIn: false,
//   onLogout: () => {},
//   onLogin: (email, password) => {}
    arrImagelist: [],
    renderedImaglist:[]
});

export default DataContext;