import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import DataContext from '../../store/data-context';


export const Fileupload = ({getimagefiles},props)=>{
    const [selectedFile, setSelectedFile] = useState();
	const [arrImagelist, setarrImagelist] = useState([]);
    const [isuploading, setisuploading] = useState(false);
	const ctx = useContext(DataContext);
let Apiurl = process.env.REACT_APP_APIURL;
	const changeHandler = (event) => {
        
		setSelectedFile(event.target.files[0]);
		
        uploadfiletoserver(event.target.files[0]);
	};

	const uploadfiletoserver = (selectedfile) => {
        const formData = new FormData();
        setisuploading(true);
		formData.append('file', selectedfile);

		fetch(
			`${Apiurl}nodejs/fileupload/upload_file`,
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
                setisuploading(false);
                getimagefiles(result);
                setarrImagelist(result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

    useEffect(()=>{
        fetch(
			`${Apiurl}nodejs/fileupload/imagelist`,
			{
				method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                  },
			}
		)
			.then((response) => response.json())
			.then((result) => {
                setarrImagelist(result);
                getimagefiles(result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
    },[]);

	return(
   <div className='fileupload-container'>
			
            <label class="custom-file-upload">
                <input type="file" name="file" multiple onChange={changeHandler} />
                <UploadOutlined />
            </label>
			<div>
				
                <Space direction="vertical">
                    <Space wrap>
                        {!isuploading ?
                        null:
                        <Button type="primary" loading>
                            Loading
                        </Button>}
                    </Space>
                    </Space>
			</div>
            
            
		</div>
	)
}