

function ImageItem() {
    // Base
    var imgItem = this;
  
    // Specifications
    imgItem.context = null;
    imgItem.src = '';
    imgItem.dx = 4;
    imgItem.dy = 4;
    imgItem.dWidth = 4000;
    imgItem.dHeight = 4000;
    imgItem.boundingRect = null;
  }

  
  ImageItem.prototype.draw = function () {
    // Base
    var imgItem = this;
    const image = new Image(); 
        
    //image.setAttribute('crossorigin', 'anonymous');
        image.src = imgItem.src;
        image.onload = ()=>{
          //imgItem.context.scale(0.25,0.25)
            imgItem.context.drawImage(image, imgItem.dx, imgItem.dy, imgItem.dWidth, imgItem.dHeight);
            //imgItem.context.scale(-1,-1)
        }

    
  };
  export default ImageItem;
  