function ImageData() {
    // Base
    var imgData = this;
  
    // Specifications
    imgData.src = '';
    imgData.rx = 0;
    imgData.ry = 0;
    imgData.rz = 0;
    imgData.mystyle = {
        top:  4 ,
        left:  4,
        zIndex:  0,
        width: '400px',
        height: '400px'
      };
    
  }
  export default ImageData;