


function Placeholder(width, height,object) {
    // Base
    var placeholder = this;
  
    // Specifications
    placeholder.width = width || 10;         // placeholder width
    placeholder.height = height || 10;         // placeholder height
    placeholder.c = 'red';       // placeholder color
    placeholder.type = 'placeholder';
    placeholder.x = 0;                    // center x
    placeholder.y = 0;                    // center y
    placeholder.m = 0;                    // mass
    placeholder.vx = 0;                   // velocity of x direction of placeholder
    placeholder.vy = 0;                   // velocity of y direction of placeholder
    placeholder.context = null            // the drawing context of placeholder
    placeholder.boundingRect = null;
    //placeholder.resizeRect = [];
    placeholder.resizeCorner = undefined;  // TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT, BOTTOM_RIGHT
    placeholder.connectorDot = undefined; //TOP, RIGHT, BOTTOM, LEFT
    placeholder.object = object;
  }
  
  Placeholder.prototype.draw = function () {
    // Base
    var placeholder = this;
  
    // Check Context
    if(!placeholder.context){return}
  
    // Draw placeholder

      
    placeholder.context.beginPath();
    placeholder.context.strokeStyle = "#005A9C";
    placeholder.context.rect(placeholder.x, placeholder.y, placeholder.width,placeholder.height);
    placeholder.context.stroke();

    placeholder.context.beginPath();
    placeholder.context.fillStyle = "#005A9C";
    placeholder.context.rect(placeholder.x - 10, placeholder.y -10, 10,10);
    placeholder.context.fill();

    placeholder.context.beginPath();
    placeholder.context.fillStyle = "#005A9C";
    placeholder.context.rect(placeholder.x + placeholder.width , placeholder.y - 10, 10,10);
    placeholder.context.fill();

    placeholder.context.beginPath();
    placeholder.context.fillStyle = "#005A9C";
    placeholder.context.rect(placeholder.x - 10, placeholder.y + placeholder.height , 10,10);
    placeholder.context.fill();

    placeholder.context.beginPath();
    placeholder.context.fillStyle = "#005A9C";
    placeholder.context.rect(placeholder.x + placeholder.width, placeholder.y + placeholder.height, 10,10);
    placeholder.resizeRect =[
      [placeholder.x + placeholder.width,placeholder.y + placeholder.height],
      [placeholder.x + placeholder.width + 10,placeholder.y + placeholder.height],
      [placeholder.x + placeholder.width + 10,placeholder.y + placeholder.width + 10],
      [placeholder.x + placeholder.width ,placeholder.y + placeholder.height + 10]
    ]
    placeholder.context.fill();
    
  };

  export default Placeholder;
  